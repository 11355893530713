import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyDPU5SXlXoSG_KyWtitjveVUdohBC3OjEo",
  authDomain: "archymedes-prod.firebaseapp.com",
  projectId: "archymedes-prod",
  storageBucket: "archymedes-prod.appspot.com",
  messagingSenderId: "599408762461",
  appId: "1:599408762461:web:b9b1cbf5925f5e795e77b4",
  measurementId: "G-G8P3XZTESG"
};

firebase.initializeApp(firebaseConfig);


const projectAuth = firebase.auth();
// projectAuth.useEmulator("http://localhost:9099");



const projectFirestore = firebase.firestore();
// if (location.hostname === "localhost") {
//   projectFirestore.useEmulator("localhost", 8080);
// }


projectAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
        console.log("Changed the Session Persistence to Session! You'll have to log in again if you leave this tab/window!");
    })

const projectStorage = firebase.storage();

const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, projectAuth, projectStorage, firebaseConfig, timestamp };